import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SelectOption } from 'projects/component-library/components/input-select/select-option';
import { environment } from 'projects/shared/environments/environment';
import { SharedValidators } from 'projects/shared/validators/shared-validators';
import { FormInputTextComponent } from 'projects/component-library/components/form-items/form-input-text/form-input-text.component';

@Component({
  selector: 'app-company-web-details-form',
  template: `
    <ng-container [formGroup]="form">
      <mac-form-input-select
        #culture
        [label]="'AdministrationPortal.InspectionCompanies.Culture.Label' | translate"
        [placeholder]="'AdministrationPortal.InspectionCompanies.Culture.Label' | translate"
        [formControl]="cultureControl"
        [options]="cultureOptions"
        [search]="true"
        name="culture" />

      <mac-form-input-text
        [label]="'AdministrationPortal.InspectionCompanies.Subdomain.Label' | translate"
        [placeholder]="'AdministrationPortal.InspectionCompanies.Subdomain.Label' | translate"
        [formControl]="subdomainControl"
        name="subdomain"
        [suffix]="baseDomainName" />
    </ng-container>
  `,
})
export class CompanyWebDetailsFormComponent implements OnInit {
  @Input() cultureOptions: SelectOption[] = [];

  @ViewChild('cultureInput') cultureInput!: ElementRef<FormInputTextComponent>;

  form: FormGroup;

  constructor(private fb: FormBuilder, private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.form = this.controlContainer.control as FormGroup;
  }

  get subdomainControl(): FormControl<string | null> {
    return this.form.get('subdomain') as FormControl<string | null>;
  }

  get cultureControl(): FormControl<string | null> {
    return this.form.get('culture') as FormControl<string | null>;
  }

  get baseDomainName(): string {
    return environment.domainName;
  }

  createForm(): FormGroup<WebDetailsForm> {
    return this.fb.group<WebDetailsForm>({
      subdomain: new FormControl<string | null>(null, [Validators.required, SharedValidators.subdomainValidator]),
      culture: new FormControl<string | null>(null, [Validators.required]),
    });
  }

  focus(): void {
    this.cultureInput.nativeElement.focus();
  }
}

export interface WebDetailsForm {
  subdomain: FormControl<string | null>;
  culture: FormControl<string | null>;
}
