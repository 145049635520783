import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NzSelectComponent, NzSelectModeType } from 'ng-zorro-antd/select';
import { SelectOption } from './select-option';

@Component({
  selector: 'mac-input-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.scss'],
})
export class InputSelectComponent implements OnInit {
  @Input() name?: string;
  @Input() mode: NzSelectModeType = 'default';
  @Input() placeholder: string | null | undefined = null;
  @Input() control: FormControl<any>;
  @Input() disabled: boolean = false;
  @Input() icon?: string;
  @Input() allowClear = true;
  @Input() search = false;
  @Input() applyFuzzyFilter = false;
  @Input() maxTagCount: number = Infinity;
  @Input() options: SelectOption[];
  @Input() dropdownStyle: { [key: string]: string } | null;
  @Input() tokenSeparators: string[] = [];

  @ViewChild('selectInput') selectInput!: ElementRef<NzSelectComponent>;

  searchTextValue: string;

  ngOnInit(): void {
    if (!this.control && this.disabled) {
      this.control = new FormControl<any>(null);
      this.control.disable();
    }
  }

  focus() {
    this.selectInput.nativeElement.focus();
  }
}
