import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mac-modal',
  templateUrl: './modal.component.html',
})
export class ModalComponent {
  /**
   * Boolean representing whether the modal is visible or not.
   */
  @Input() open? = false;
  @Input() width: ModalWidth = 'default';

  @Output() openChange = new EventEmitter<boolean>();

  get modalWidth(): string {
    switch (this.width) {
      case 'large':
        return '75vw';
      case 'default':
      default:
        return '50vw';
    }
  }

  openModal(): void {
    this.setOpen(true);
  }

  closeModal(): void {
    this.setOpen(false);
  }

  onCancel(): void {
    this.closeModal();
  }

  private setOpen(open: boolean): void {
    this.open = open;
    this.openChange.emit(this.open);
  }
}

export type ModalWidth = 'default' | 'large';
