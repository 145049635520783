<nz-form-item>
  <nz-form-control class="custom-form-control" [nzSpan]="inputSpan" [nzErrorTip]="createErrorKey(error) | translate"
    [nzExtra]="message"
    nzHasFeedback [nzValidateStatus]="validateStatus">
    <label>{{ label }}</label>
    <nz-date-picker
      #datePicker
      [nzAllowClear]="true"
      class="custom-input"
      style="width: 100%;"
      [formControl]="control"
      [nzPlaceHolder]="placeholder || ''"
      nzSize="large">
    </nz-date-picker>
  </nz-form-control>
</nz-form-item>
