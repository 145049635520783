<nz-form-item style="width: 100%;">
  <nz-form-control [nzSpan]="inputSpan" [nzErrorTip]="createErrorKey(error) | translate" [nzExtra]="message" nzHasFeedback
    [nzValidateStatus]="validateStatus">
    <div nz-row>
      <label nz-col nzFlex="50">{{ label }}</label>
      <label nz-col class="actionLabel" (click)="onActionLabelClick()">{{ actionLabel }}</label>
    </div>
    <mac-input-text #inputComponent nzFlex="50" style="width: 100%;" [suffix]="suffix" [control]="control"
      [placeholder]="placeholder"
      [type]="type" [name]="name" nzSize="large" [addOnAfter]="addOnAfter">
    </mac-input-text>
  </nz-form-control>
</nz-form-item>