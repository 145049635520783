import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormInputTextComponent } from 'projects/component-library/components/form-items/form-input-text/form-input-text.component';

@Component({
  selector: 'app-address-details-form',
  template: `
    <ng-container [formGroup]="form">
      <div nz-row>
        <div nz-col nzSpan="24">
          <mac-form-input-text
            #description
            [label]="'AdministrationPortal.InspectionCompanies.Address.Description.Label' | translate"
            [placeholder]="'AdministrationPortal.InspectionCompanies.Address.Description.Label' | translate"
            [formControl]="descriptionControl"
            name="address-description" />
        </div>
      </div>

      <div nz-row [nzGutter]="8">
        <div nz-col [nzXs]="24" [nzMd]="16">
          <mac-form-input-text
            [label]="'AdministrationPortal.InspectionCompanies.Address.StreetLine1.Label' | translate"
            [placeholder]="'AdministrationPortal.InspectionCompanies.Address.StreetLine1.Label' | translate"
            [formControl]="streetLine1Control"
            name="address-street-line1" />
        </div>
        <div nz-col [nzXs]="24" [nzMd]="4">
          <mac-form-input-text
            [label]="'AdministrationPortal.InspectionCompanies.Address.Number.Label' | translate"
            [placeholder]="'AdministrationPortal.InspectionCompanies.Address.Number.Label' | translate"
            [formControl]="numberControl"
            name="address-number" />
        </div>
        <div nz-col [nzXs]="24" [nzMd]="4">
          <mac-form-input-text
            [label]="'AdministrationPortal.InspectionCompanies.Address.NumberBis.Label' | translate"
            [placeholder]="'AdministrationPortal.InspectionCompanies.Address.NumberBis.Label' | translate"
            [formControl]="numberBisControl"
            name="address-number-bis" />
        </div>
      </div>

      <div nz-row [nzGutter]="8">
        <div nz-col [nzXs]="24" [nzMd]="8">
          <mac-form-input-text
            [label]="'AdministrationPortal.InspectionCompanies.Address.PostalCode.Label' | translate"
            [placeholder]="'AdministrationPortal.InspectionCompanies.Address.PostalCode.Label' | translate"
            [formControl]="postalCodeControl"
            name="address-postal-code" />
        </div>
        <div nz-col [nzXs]="24" [nzMd]="16">
          <mac-form-input-text
            [label]="'AdministrationPortal.InspectionCompanies.Address.City.Label' | translate"
            [placeholder]="'AdministrationPortal.InspectionCompanies.Address.City.Label' | translate"
            [formControl]="cityControl"
            name="address-city" />
        </div>
      </div>

      <div nz-row>
        <div nz-col [nzSpan]="24">
          <app-country-input-select [formControl]="countryIdControl" name="address-country" />
        </div>
      </div>
    </ng-container>
  `,
})
export class AddressDetailsFormComponent implements OnInit {
  public form: FormGroup;

  descriptionControl = new FormControl<string | null>(null, [Validators.required]);
  streetLine1Control = new FormControl<string | null>(null, [Validators.required]);
  numberControl = new FormControl<string | null>(null, [Validators.required]);
  numberBisControl = new FormControl<string | null>(null);
  postalCodeControl = new FormControl<string | null>(null, [Validators.required]);
  cityControl = new FormControl<string | null>(null, [Validators.required]);
  countryIdControl = new FormControl<number | null>(null, [Validators.required]);

  @ViewChild('description') descriptionComponent!: FormInputTextComponent;

  constructor(private fb: FormBuilder, private controlContainer: ControlContainer) {}

  focus(): void {
    this.descriptionComponent.focus();
  }

  ngOnInit(): void {
    this.form = this.controlContainer.control as FormGroup;
  }

  public createForm(): FormGroup<AddressForm> {
    return this.fb.group<AddressForm>({
      description: this.descriptionControl,
      streetLine1: this.streetLine1Control,
      number: this.numberControl,
      numberBis: this.numberBisControl,
      postalCode: this.postalCodeControl,
      city: this.cityControl,
      countryId: this.countryIdControl,
    });
  }
}

export interface AddressForm {
  description: FormControl<string | null>;
  streetLine1: FormControl<string | null>;
  number: FormControl<string | null>;
  numberBis: FormControl<string | null>;
  postalCode: FormControl<string | null>;
  city: FormControl<string | null>;
  countryId: FormControl<number | null>;
}
