import { Component, Input } from '@angular/core';

@Component({
  selector: 'mac-modal-content',
  template: ` <div class="modal-content" [style.min-height]="minHeight">
    <ng-content></ng-content>
  </div>`,
  styles: [
    `
      .modal-content {
        padding: 2rem;
        overflow-y: auto;
        max-height: 65vh;
      }
    `,
  ],
})
export class ModalContentComponent {
  @Input() minHeight?: string;
}
