<button
  *ngIf="!routerLink"
  nz-button
  [nzType]="nzButtonVariant"
  [attr.type]="type"
  [attr.form]="form"
  [disabled]="disabled"
  [attr.aria-disabled]="disabled"
  (click)="(clickEvent)"
  [nzSize]="size"
  [ngClass]="{ 'show-icon-right': iconPlacement === 'right', 'compact': padding === 'compact', 'multiline': multiline, 'no-hover': disableHoverState }"
  [nzBlock]="showAsBlock"
  [nzLoading]="isLoading"
  [attr.tabindex]="tabindex"
>
  <mac-icon *ngIf="icon" [icon]="icon" class="icon-font-variation">
  </mac-icon>
  <span class="button-content">
    <ng-container *ngTemplateOutlet="ngContent"></ng-container>
  </span>
</button>

<a *ngIf="routerLink"
  nz-button
  [nzType]="nzButtonVariant"
  [attr.type]="type"
  [attr.form]="form"
  [disabled]="disabled"
  [attr.aria-disabled]="disabled"
  (click)="(clickEvent)"
  [nzSize]="size"
  [ngClass]="{ 'show-icon-right': iconPlacement === 'right', 'compact': padding === 'compact', 'multiline': multiline }"
  [nzBlock]="showAsBlock"
  [nzLoading]="isLoading"
  [routerLink]="routerLink"
  [attr.tabindex]="tabindex"
>
  <mac-icon *ngIf="icon" [icon]="icon" class="icon-font-variation">
  </mac-icon>
  <span class="button-content">
    <ng-container *ngTemplateOutlet="ngContent"></ng-container>
  </span>
</a>

<ng-template #ngContent>
  <ng-content></ng-content>
</ng-template>
