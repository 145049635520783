import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, shareReplay } from 'rxjs';
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class CountriesService {
    constructor(private httpClient: HttpClient) { }

    getCountries(): Observable<CountryResponse[]> {
        const url = `${environment.restApiBaseUrl}/api/countries`;
        return this.httpClient.get<CountryResponse[]>(url);
    }

    countries$ = this.getCountries()
      .pipe(
        shareReplay({ bufferSize: 1, refCount: true }));
}

export interface CountryResponse {
    id: number;
    iso: string;
    name: string;
}