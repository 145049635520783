import { Component, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedValidators } from 'projects/shared/validators/shared-validators';
import { FormInputTextComponent } from 'projects/component-library/components/form-items/form-input-text/form-input-text.component';

@Component({
  selector: 'app-company-details-form',
  template: `
    <ng-container [formGroup]="form">
      <mac-form-input-text
        #name
        [label]="'AdministrationPortal.InspectionCompanies.Name.Label' | translate"
        [placeholder]="'AdministrationPortal.InspectionCompanies.Name.Label' | translate"
        [formControl]="nameControl"
        name="name" />

      <mac-form-input-text
        [label]="'AdministrationPortal.InspectionCompanies.EmailAddress.Label' | translate"
        [placeholder]="'AdministrationPortal.InspectionCompanies.EmailAddress.Label' | translate"
        [formControl]="emailControl"
        type="email"
        name="email" />

      <mac-form-input-text
        [label]="'AdministrationPortal.InspectionCompanies.VatNumber.Label' | translate"
        [placeholder]="'AdministrationPortal.InspectionCompanies.VatNumber.Label' | translate"
        [formControl]="vatNumberControl"
        name="vatnumber" />
    </ng-container>
  `,
})
export class CompanyDetailsFormComponent implements OnInit {
  public form: FormGroup;

  @ViewChild(FormInputTextComponent) nameInput!: FormInputTextComponent;

  constructor(private fb: FormBuilder, private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.form = this.controlContainer.control as FormGroup;
  }

  get nameControl(): FormControl<string | null> {
    return this.form.get('name') as FormControl<string | null>;
  }

  get emailControl() {
    return this.form.get('email') as FormControl<string | null>;
  }

  get vatNumberControl() {
    return this.form.get('vatNumber') as FormControl<string | null>;
  }

  createForm(): FormGroup<CompanyDetailsForm> {
    return this.fb.group<CompanyDetailsForm>({
      name: new FormControl<string | null>(null, [Validators.required]),
      email: new FormControl<string | null>(null, [Validators.email, Validators.required]),
      vatNumber: new FormControl<string | null>(null, [Validators.required, SharedValidators.vatNumberValidator]),
    });
  }

  focus(): void {
    this.nameInput.focus();
  }
}

export interface CompanyDetailsForm {
  name: FormControl<string | null>;
  email: FormControl<string | null>;
  vatNumber: FormControl<string | null>;
}
