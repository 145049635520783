import { Component, OnDestroy, OnInit } from '@angular/core';
import { SelectOption } from 'projects/component-library/components/input-select/select-option';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CountriesService } from 'projects/shared/services/countries/countries.service';
import { FormInputComponentBase } from 'projects/component-library/components/form-items/form-input-base.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-country-input-select',
  template: ` <mac-form-input-select
    [label]="'AdministrationPortal.InspectionCompanies.Address.Country.Label' | translate"
    [placeholder]="'AdministrationPortal.InspectionCompanies.Address.Country.Label' | translate"
    [options]="countries"
    [formControl]="formControl"
    [applyFuzzyFilter]="true"
    [search]="true"
    name="country">
  </mac-form-input-select>`,
  providers: [
      {
        provide: NG_VALUE_ACCESSOR,
        multi: true,
        useExisting: CountryInputSelectComponent,
      },
    ],
})
export class CountryInputSelectComponent extends FormInputComponentBase implements OnInit, OnDestroy {
  countries: SelectOption[] = [];
  private countries$: Subscription;

  constructor(
    private countriesService: CountriesService,
    private translateService: TranslateService,
    controlContainer: ControlContainer) {
        super(controlContainer);
    }

    ngOnInit(): void {
      this.fetchCountries();
    }
  
    ngOnDestroy(): void {
      this.countries$.unsubscribe();
    }

  private fetchCountries() {
    this.countries$ = this.countriesService.countries$.subscribe({
      next: (countries) => {
        this.countries = countries.map((x): SelectOption => ({ 
          id: x.id,
          label: this.translateService
            .instant(`Shared.Countries.${x.iso}.Label`) }))
            .sort((a, b) => a.label.localeCompare(b.label));
      },
      error: () => {
        this.formControl.setValue([]);
        this.formControl.setErrors({ CouldNotLoadCountries: "Failed to load countries" });
        this.formControl.markAllAsTouched();
        this.formControl.disable();
      },
    });
  }
}
