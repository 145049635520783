import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { FormInputComponentBase } from 'projects/component-library/components/form-items/form-input-base.component';
import { SelectOption } from 'projects/component-library/components/input-select/select-option';
import { RoleService } from '../../../services/roles/role.service';
import { isDebugEnvironment } from '../../../utils/environmentHelpers';
import { getSubdomainFromRoute } from '../../../utils/subdomainHelper';

@Component({
  selector: 'app-roles-select-input',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: RolesSelectInputComponent,
    },
  ],
  template: `
    <mac-form-input-select
      [formControl]="formControl"
      mode="tags"
      [label]="'Domain.User.Roles.Label' | translate"
      [placeholder]="'Domain.User.Roles.Placeholder' | translate"
      [actionLabel]="'Domain.User.Roles.InfoText' | translate"
      (actionLabelClick)="onClickRolesLearnMore()"
      [options]="roles">
    </mac-form-input-select>
  `,
})
export class RolesSelectInputComponent extends FormInputComponentBase implements OnInit {
  @Input() moreInfoLink = 'roles';
  @Output('error') httpErrors = new EventEmitter<string>();

  public roles: SelectOption[] = [];

  constructor(controlContainer: ControlContainer, private router: Router, private route: ActivatedRoute, private roleService: RoleService) {
    super(controlContainer);
  }

  ngOnInit(): void {
    this.fetchRoles();
  }

  public onClickRolesLearnMore() {
    const commands = isDebugEnvironment() ? [getSubdomainFromRoute(this.route), 'roles'] : [this.moreInfoLink];
    const url = this.router.serializeUrl(this.router.createUrlTree(commands));

    window.open(url, '_blank');
  }

  private fetchRoles() {
    this.formControl.disable();
    this.roleService.assignableRoles$.subscribe({
      next: (rolesResponse) => {
        this.roles = rolesResponse.map((role) => ({
          id: role.key,
          label: `Domain.Role.${role.key}.Label`,
        }));
        this.formControl.enable();
      },
      error: () => {
        this.formControl.setValue([]);
        this.formControl.setErrors({ CouldNotLoadRoles: 'Failed to load roles.' });
        this.httpErrors.emit('CouldNotLoadRoles');
      },
    });
  }
}
