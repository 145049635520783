<nz-form-item>
  <nz-form-control
    [nzSpan]="inputSpan"
    [nzErrorTip]="createErrorKey(error) | translate"
    [nzExtra]="message"
    nzHasFeedback
    [nzValidateStatus]="validateStatus">
    <div nz-row>
      <label nz-col nzFlex="50">{{ label }}</label>
      <label nz-col class="actionLabel" (click)="onActionLabelClick()">{{ actionLabel }}</label>
    </div>
    <mac-input-select
      #selectInput
      [icon]="icon"
      [control]="control"
      [placeholder]="placeholder"
      [mode]="mode"
      [name]="name"
      [options]="options"
      [search]="search"
      [applyFuzzyFilter]="applyFuzzyFilter"
      [allowClear]="allowClear"
      [maxTagCount]="maxTagCount">
    </mac-input-select>
  </nz-form-control>
</nz-form-item>
