import { Component, ElementRef, forwardRef, Input, TemplateRef, ViewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormInputComponentBase } from '../form-input-base.component';
import { createErrorKey } from 'projects/shared/utils/getErrorMessage';
import { InputTextComponent } from '../../input-text/input-text.component';

@Component({
  selector: 'mac-form-input-text',
  templateUrl: './form-input-text.component.html',
  styleUrls: ['../form-input-base.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormInputTextComponent),
    },
  ],
})
export class FormInputTextComponent extends FormInputComponentBase implements ControlValueAccessor {
  @Input() suffix?: string | TemplateRef<void>;
  @Input() addOnAfter?: string | TemplateRef<void>;

  @ViewChild(InputTextComponent) inputComponent!: InputTextComponent;

  constructor(controlContainer: ControlContainer) {
    super(controlContainer);
  }

  createErrorKey(key: string): string {
    return createErrorKey(key);
  }

  focus() {
    this.inputComponent.focus();
  }
}
