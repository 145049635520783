<nz-input-group [nzSuffix]="suffix ?? suffixIcon" [nzPrefix]="prefix" nzSize="large" [nzAddOnAfter]="addOnAfter">
  <input #inputComponent nz-input [formControl]="control" [placeholder]="placeholder" [attr.type]="type" [name]="name"
    [id]="name"
    title="{{ name }}" />
</nz-input-group>
<ng-template #suffixIcon>
  <mac-icon *ngIf="icon" [icon]="icon"></mac-icon>
  <span id="suffix-content">
    <ng-content></ng-content>
    <!--you can pass <svg> as content to be displayed as icon-->
  </span>
</ng-template>