import { Component, ElementRef, ViewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { createErrorKey } from 'projects/shared/utils/getErrorMessage';
import { FormItemComponentBase } from '../form-item-base.component';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';

@Component({
  selector: 'app-form-item-date',
  templateUrl: './form-item-date.component.html',
  styleUrls: ['./form-item-date.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FormItemDateComponent,
    },
  ],
})
export class FormItemDateComponent extends FormItemComponentBase implements ControlValueAccessor {
  @ViewChild(NzDatePickerComponent) datePickerComponent: ElementRef<NzDatePickerComponent>;

  constructor(controlContainer: ControlContainer) {
    super(controlContainer);
  }

  createErrorKey(key: string): string {
    return createErrorKey(key);
  }

  focus() {
    this.datePickerComponent.nativeElement.focus();
  }
}
